import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Loading, RadioGroup, Text, useNotifications } from "@ilc-technology/luik";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import Enrich from "../../../Common/services/TextEnricher";
import {
  ApiRequestType,
  ErrorCode,
  ErrorDetails,
  InitiateOraclePaymentRequest,
  InitiateOraclePaymentSessionResponse,
  Money,
  OraclePaymentLibraryResult,
  OraclePaymentResult,
  PaymentInfo,
  PaymentInfoStatus,
  PaymentOption,
  PaymentStatus,
  Quote,
  QuotePaymentStatus,
  ReservationRequest,
  ReservationResponse,
} from "../../../Common/Types";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { FormattedMoney } from "../../../Common/Helpers/MoneyHelper";
import { AppConfig } from "../../../AppConfig";
import { GenerateHeaderInformation } from "../../../Common/Helpers/ApiHelper";
import { AdyenResultCodes, JWT_API_KEY, TRUE } from "../../../Common/Constants";
import InformationBox from "../../InformationBox/InformationBox";
import StudentDetails from "./StudentDetails";
import { CustomTrackingEvent, trackEvent, trackPurchaseEvent } from "../../../Common/services/Analytics";
import { fetchRetry } from "../../../Common/services/FetchRetry";
import { generatePaymentSummaryUrl } from "../../../Common/generatePaymentSummaryUrl";
import {
  convertToErrorDetails,
  getErrorDetailsToDisplay,
  logError,
  returnJsonOrThrowError,
} from "../../../Common/services/ErrorService";
import { generatePriceQuoteEventItems } from "../../../Common/services/AnalyticsMappings";
import { PaymentDetailsResponse } from "../../../apis/quotationApi";
import TBRadio from "../../BaseComponents/TBRadio";
import TermsAndConditions, { SalesCondition } from "./TermsAndConditions";
import TBSection from "../../BaseComponents/TBSection";
import PaymentSummaryCell from "./PaymentSummaryCell";
import TBCardStatus, { TBCardStatusAlignment, TBCardStatusPosition } from "../../BaseComponents/TBCardStatus";
import { ColorVariant } from "../../../Common/services/ColorService";
import TitleValueCell from "../../BaseComponents/TitleValueCell";
import { formatDateRange } from "../../../Common/Helpers/DateHelper";
import InfoCard from "../../BaseComponents/InfoCard";
import ContentLayout from "../../BaseComponents/ContentLayout";
import { getDestinationName, getQuoteInformation } from "../../../Common/services/QuoteService";

interface PaymentProps {
  quote: Quote;
  reservationPrice: Money;
}

type PaymentOptionRadio = {
  title: string;
  price: Money;
  subtitle: string;
  value: PaymentOption;
  testId: string;
};

const Payment: React.FC<PaymentProps> = ({ quote, reservationPrice }) => {
  const { pathname, search } = useLocation();
  const { quoteId } = useParams();
  const { session, language } = useSessionContext();
  const { quotesContentCache, activeLanguage, addPaymentToQuote } = useQuoteContext();
  const { labels, featureSettings } = useDatasources();
  const navigate = useNavigate();
  const { user } = session;
  const notifications = useNotifications();
  const isFullPaymentEnabled = featureSettings.isFullPaymentEnabled === TRUE;
  const isPaymentEnabled = featureSettings.isPaymentEnabled === TRUE;
  const isReservationEnabled = featureSettings.isReservationEnabled === TRUE;
  const showOnlyReservation = !isPaymentEnabled && isReservationEnabled;
  const paymentChooser = useRef(null);

  const [isUserInfoValid, setIsUserInfoValid] = useState(!!user?.country);
  const [acceptedTermsVersion, setAcceptedTermsVersion] = useState<string | undefined>(undefined);
  const [acceptedConditions, setAcceptedConditions] = useState(0);
  const [isLoadingPaymentSession, setIsLoadingPaymentSession] = useState<boolean | undefined>(undefined);
  const [isLoadingReservation, setIsLoadingReservation] = useState<boolean>(false);
  const [referenceId, setReferenceId] = useState<string | undefined>(undefined);
  const [destinationName, setDestinationName] = useState<string>("");
  const [showPaymentFailureError, setShowPaymentFailureError] = useState<ErrorDetails | undefined>(undefined);
  const [chosenPaymentOption, setChosenPaymentOption] = useState(PaymentOption.PartialPayment);
  const quoteInfo = getQuoteInformation(quote);

  const allTermsAccepted = (termsValue: SalesCondition) => {
    return (termsValue & SalesCondition.TermsAccepted) > 0 && (termsValue & SalesCondition.PrivacyPolicyAccepted) > 0;
  };

  const canShowPaymentElements =
    isUserInfoValid &&
    allTermsAccepted(acceptedConditions) &&
    showPaymentFailureError === undefined &&
    referenceId !== undefined;
  const weeksLabel =
    quoteInfo.totalDuration === 1
      ? labels[LabelKey.durationWeek]
      : quoteInfo.totalDuration > 4
        ? labels[LabelKey.duration5orMoreWeeks]
        : labels[LabelKey.duration2to4Weeks];
  const paymentValue = chosenPaymentOption == PaymentOption.FullPayment ? quote.prices?.totalPrice : reservationPrice;
  const quoteStatus = quote.status;
  const radioOptions: PaymentOptionRadio[] = [
    {
      title: labels[LabelKey.downPayment],
      subtitle: labels[LabelKey.downPaymentDetails],
      value: PaymentOption.PartialPayment,
      price: reservationPrice,
      testId: "down-payment-amount",
    },
    {
      title: labels[LabelKey.fullPayment],
      subtitle: labels[LabelKey.fullPaymentDetails],
      value: PaymentOption.FullPayment,
      price: quote.prices?.totalPrice,
      testId: "full-payment-amount",
    },
  ];

  useEffect(() => {
    if (quotesContentCache && quoteInfo.courseSegments.length != 0) {
      setDestinationName(getDestinationName(quoteInfo.courseSegments, quotesContentCache?.destinations));
    }
  }, [quotesContentCache, quoteInfo.courseSegments]);

  const redirectToPaymentSummary = (
    status: PaymentStatus,
    referenceId: string | undefined,
    paymentValue: Money | undefined
  ) => {
    const redirectUrl = generatePaymentSummaryUrl(status, referenceId, paymentValue, quoteId!);
    trackEvent(
      quote.opportunityUuid,
      chosenPaymentOption ? CustomTrackingEvent.FullPaymentChosen : CustomTrackingEvent.DownPaymentChosen
    );
    navigate(redirectUrl);
  };

  const sendReservationEvent = async () => {
    setIsLoadingReservation(true);
    fetchRetry(`${AppConfig.api.quotation}/reservations`, {
      method: "POST",
      headers: GenerateHeaderInformation(session.jwtAccessToken, JWT_API_KEY),
      body: JSON.stringify({
        quoteExternalId: quote.id,
        termsVersion: acceptedTermsVersion,
      } as ReservationRequest),
    })
      .then((response) => returnJsonOrThrowError<ReservationResponse>(response))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) => {
        trackEvent(quote.opportunityUuid, CustomTrackingEvent.Reserved);
        const payment: PaymentInfo = {
          payment: {
            amount: 0.0,
            currency: quote.prices.totalPrice.currency,
          },
          status: PaymentInfoStatus.Reserve,
          madeAt: new Date().toISOString(),
        };
        addPaymentToQuote(quote, payment);
        setIsLoadingReservation(false);
        redirectToPaymentSummary(PaymentStatus.ReservationSuccess, undefined, undefined);
      })
      .catch((error) => {
        setIsLoadingReservation(false);
        notifications.addErrorNotification({
          title: labels[LabelKey.paymentStatus_ReservationFailureHeader],
          description: labels[LabelKey.paymentStatus_ReservationFailureMessage],
        });
        logError(convertToErrorDetails(error, ErrorCode.ReservationsPostFailed));
      });
  };

  const initiateOraclePaymentSession = () => {
    if (isLoadingPaymentSession) {
      return;
    }
    const request: InitiateOraclePaymentRequest = {
      externalId: quote.id,
      termsVersion: acceptedTermsVersion!,
      returnUrl: `${AppConfig.oraclePaymentLibrary.source}/payment/` + quote.id,
      countryCode: session.user.country,
      paymentSource: "Trip Booker",
      efComPaymentSource: "MyEFPlanner-Adyen",
      // payment reference
      paymentReferenceAction: "Pay for quote",
      requestedPaymentAmount:
        chosenPaymentOption == PaymentOption.FullPayment ? quote.prices.totalPrice.amount : reservationPrice.amount,
    };

    setIsLoadingPaymentSession(true);
    setShowPaymentFailureError(undefined);
    fetchRetry(`${AppConfig.api.quotation}/payments/initiate`, {
      method: "POST",
      redirect: "manual",
      headers: GenerateHeaderInformation(session.jwtAccessToken, JWT_API_KEY),
      body: JSON.stringify(request),
    })
      .then((response) => returnJsonOrThrowError<InitiateOraclePaymentSessionResponse>(response))
      .then((res) => {
        if (!document.getElementById("paymentForm")) {
          return;
        }
        if (!res.correlationId) {
          const errorDetails = {
            code: ErrorCode.InitPaymentSessionFailed,
            details: {
              additionalDetails: JSON.stringify(res),
            },
          };
          setIsLoadingPaymentSession(false);
          setShowPaymentFailureError(errorDetails);
          logError(errorDetails);
          return;
        }

        setReferenceId(res.correlationId);
        // @ts-expect-error TODO: Add typings
        window.adyenDropin && window.adyenDropin.destroy();
        // @ts-expect-error TODO: Add typings
        window.adyenDropin = new window.EF.AdyenDropin();
        // @ts-expect-error TODO: Add typings
        window.adyenDropin.startSessionsFlow({
          containerId: "#paymentForm",
          sessionId: res.sessionId,
          sessionData: res.sessionData,
          correlationId: res.correlationId,
          onReady: function () {
            setIsLoadingPaymentSession(false);
          },
          onPaymentCompleted: function (result: OraclePaymentLibraryResult) {
            const payment: PaymentInfo = {
              payment: {
                amount: paymentValue?.amount,
                currency: quote.prices.totalPrice.currency,
              },
              status: PaymentInfoStatus.Paid,
              madeAt: new Date().toISOString(),
            };
            if (result.resultCode === AdyenResultCodes.Authorised) {
              trackPurchaseEvent(
                res.merchantReference,
                quote.opportunityUuid,
                generatePriceQuoteEventItems(quote),
                paymentValue
              );
              addPaymentToQuote(quote, payment);
              redirectToPaymentSummary(PaymentStatus.PaymentSuccess, res.correlationId, paymentValue);
            } else if (
              result.resultCode === AdyenResultCodes.Pending ||
              result.resultCode === AdyenResultCodes.Received
            ) {
              trackPurchaseEvent(
                res.merchantReference,
                quote.opportunityUuid,
                generatePriceQuoteEventItems(quote),
                paymentValue
              );
              addPaymentToQuote(quote, payment);
              redirectToPaymentSummary(PaymentStatus.PaymentProcessed, res.correlationId, paymentValue);
            } else if (isError(result.resultCode)) {
              const details: OraclePaymentResult = {
                resultCode: result.resultCode,
                sessionId: res.sessionId,
                correlationId: res.correlationId,
              };
              const errorDetails: ErrorDetails = {
                code: ErrorCode.PaymentFailed,
                details: {
                  additionalDetails: JSON.stringify(details),
                },
              };
              logError(errorDetails);
              setShowPaymentFailureError(errorDetails);
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: function (error: any) {
            setIsLoadingPaymentSession(false);
            const errorDetails: ErrorDetails = {
              code: ErrorCode.PaymentGatewayReturnedError,
              details: {
                additionalDetails: `${error.message} ${JSON.stringify(error?.cause ?? "")}`,
              },
            };
            logError(errorDetails);
            setShowPaymentFailureError(errorDetails);
          },
        });
      })
      .catch((error) => {
        setIsLoadingPaymentSession(false);
        const errorDetails = convertToErrorDetails(error, ErrorCode.InitPaymentSessionFailed);
        setShowPaymentFailureError(errorDetails);
        logError(convertToErrorDetails(error, ErrorCode.OracleSessionInitFailed));
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollToElement = (myElementRef: any) => {
    if (myElementRef?.current) {
      myElementRef?.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const isError = (result: string) => {
    return (
      result === AdyenResultCodes.Error || result === AdyenResultCodes.Cancelled || result === AdyenResultCodes.Refused
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      (acceptedConditions & SalesCondition.TermsAccepted) > 0 &&
      (acceptedConditions & SalesCondition.PrivacyPolicyAccepted) > 0 &&
      isUserInfoValid &&
      quote !== undefined &&
      session.user
    ) {
      initiateOraclePaymentSession();
      scrollToElement(paymentChooser);
    }
  }, [paymentValue, acceptedConditions, quote]);

  useEffect(() => {
    if (search) {
      const qs = new URLSearchParams(search);
      const correlationId = qs.get("correlationId");
      const sessionId = qs.get("sessionId");
      const redirectResult = qs.get("redirectResult");
      const merchantReference = qs.get("merchantReference");

      if (sessionId && redirectResult && correlationId && window.EF?.AdyenDropin) {
        const adyenDropin = new window.EF.AdyenDropin();

        fetchRetry(`${AppConfig.api.quotation}/payments?correlationId=${correlationId}`, {
          method: ApiRequestType.GET,
          headers: GenerateHeaderInformation(session.jwtAccessToken, JWT_API_KEY),
        })
          .then((response) => returnJsonOrThrowError<PaymentDetailsResponse>(response))
          .then((res) => {
            if (res) {
              // @ts-expect-error TODO: Add typings
              adyenDropin.submitRedirectResult({
                sessionId: encodeURIComponent(sessionId),
                redirectResult: encodeURIComponent(redirectResult),

                onPaymentCompleted: function (result: OraclePaymentLibraryResult) {
                  const value = { amount: res.amount, currency: res.currency };

                  switch (result.resultCode) {
                    case AdyenResultCodes.Authorised:
                      paymentValue &&
                        merchantReference &&
                        trackPurchaseEvent(
                          merchantReference,
                          quote.opportunityUuid,
                          generatePriceQuoteEventItems(quote),
                          res
                        );
                      redirectToPaymentSummary(PaymentStatus.PaymentSuccess, correlationId, value);
                      break;
                    case AdyenResultCodes.Pending:
                    case AdyenResultCodes.Received:
                    case AdyenResultCodes.PresentToShopper:
                      paymentValue &&
                        merchantReference &&
                        trackPurchaseEvent(
                          merchantReference,
                          quote.opportunityUuid,
                          generatePriceQuoteEventItems(quote),
                          res
                        );
                      redirectToPaymentSummary(PaymentStatus.PaymentProcessed, correlationId, value);
                      break;
                    case AdyenResultCodes.Error:
                    case AdyenResultCodes.Refused:
                    case AdyenResultCodes.Cancelled:
                      navigate(pathname);
                      setShowPaymentFailureError({
                        code: ErrorCode.ReceivingPaymentsWasCancelled,
                        details: {
                          additionalDetails: `Payment correlation id: ${correlationId}`,
                        },
                      });
                      break;
                  }
                },
                onError: function () {
                  navigate(pathname);
                  const errorDetails: ErrorDetails = {
                    code: ErrorCode.ReceivingPaymentsError,
                  };
                  setShowPaymentFailureError(errorDetails);
                },
              });
            }
          })
          .catch((error) => {
            logError(
              convertToErrorDetails(error, ErrorCode.PaymentsWithCorrelationFailedToFetch, {
                additionalDetails: `Payment correlation id: ${correlationId}`,
              })
            );
          });
      }
    }
  }, [search, window.EF?.AdyenDropin]);

  const handleAcceptanceChange = (acceptedCondition: SalesCondition, termsVersion: string) => {
    let event = CustomTrackingEvent.PrivacyPolicyAccepted;
    if (acceptedConditions === SalesCondition.TermsAccepted) {
      event = CustomTrackingEvent.TermsAndConditionsAccepted;
    }
    setAcceptedTermsVersion(termsVersion);
    trackEvent(quote.opportunityUuid, event);
    const updatedConditions = acceptedConditions ^ acceptedCondition; // Toggle the bit
    setAcceptedConditions(updatedConditions);
    if (showPaymentFailureError) {
      setReferenceId(undefined);
      setShowPaymentFailureError(undefined);
    }
  };

  return (
    <ContentLayout
      testId="payment-page"
      fullSizePanelShownFirst={true}
      topStaticCard={
        <div className="pt-6 lg:pt-8">
          <Text variant="heading-3-bold">{labels[LabelKey.completeBooking]}</Text>
        </div>
      }
      sidePanel={
        <InfoCard
          summary={`${formatDateRange(quoteInfo.startAt, quoteInfo.endAt, language)} | ${Enrich(weeksLabel, { numWeeks: quoteInfo.totalDuration })}`}
          destinationName={destinationName}
          contentText={quoteInfo.courseInfo}
          amountLabel={labels[LabelKey.total]}
          amount={quote.prices.totalPrice}
          bottomChildren={
            <TBCardStatus
              variant={ColorVariant.Black}
              alignment={TBCardStatusAlignment.Spread}
              position={TBCardStatusPosition.UnderCard}
            >
              <TitleValueCell
                leftTextValue={
                  chosenPaymentOption == PaymentOption.FullPayment
                    ? labels[LabelKey.fullPaymentLabel]
                    : labels[LabelKey.downPaymentLabel]
                }
                rightTextValue={FormattedMoney(
                  chosenPaymentOption == PaymentOption.FullPayment ? quote.prices.totalPrice : reservationPrice
                )}
                testId="payment-summary-to-pay"
              />
            </TBCardStatus>
          }
        />
      }
    >
      <div className="a-gap flex w-full flex-col">
        <TBSection title={labels[LabelKey.studentDetails]}>
          <StudentDetails
            onStudentDetailsChange={(newStudentDetails) => {
              setIsUserInfoValid(!!newStudentDetails?.country);
            }}
          />
        </TBSection>
        {activeLanguage && (
          <TBSection title={labels[LabelKey.bookingConditions]}>
            <TermsAndConditions
              activeLanguage={activeLanguage}
              market={quote.market}
              program={quote.program}
              onConditionsAcceptanceChange={handleAcceptanceChange}
            />
          </TBSection>
        )}
        {isPaymentEnabled && quoteStatus !== QuotePaymentStatus.Paid && paymentValue && (
          <TBSection title={labels[LabelKey.payment]} ref={paymentChooser}>
            {isUserInfoValid && isFullPaymentEnabled && allTermsAccepted(acceptedConditions) && (
              <Text variant="label-xl-bold">{labels[LabelKey.paymentInformation]}</Text>
            )}
            {isUserInfoValid && isFullPaymentEnabled && allTermsAccepted(acceptedConditions) && (
              <div data-testid="payment-options">
                <RadioGroup
                  aria-label="payment-options"
                  className="a-radio-group"
                  containerClassName="w-full"
                  value={chosenPaymentOption}
                  defaultValue={PaymentOption.PartialPayment}
                >
                  {radioOptions.map((option) => {
                    return (
                      <TBRadio
                        key={option.value}
                        value={option.value}
                        title={option.title}
                        description={FormattedMoney(option.price)}
                        isDisabled={isLoadingPaymentSession === true}
                        isSelected={chosenPaymentOption == option.value}
                        onClick={() => {
                          if (chosenPaymentOption == option.value) {
                            return;
                          }
                          setReferenceId(undefined);
                          setChosenPaymentOption(option.value);
                        }}
                        testIdPrefix={option.value.toLowerCase()}
                      >
                        <Text variant="label-sm" className="text-gray-45">
                          {option.subtitle}
                        </Text>
                      </TBRadio>
                    );
                  })}
                </RadioGroup>
              </div>
            )}
            {!isUserInfoValid && (
              <InformationBox
                data-testid="check-tandc-warning"
                intent="warning"
                title={labels[LabelKey.studentDetails]}
                iconName={"alert-circle-outlined"}
                testid="student-warning-info-box"
              />
            )}
            {(acceptedConditions & SalesCondition.TermsAccepted) <= 0 && (
              <InformationBox
                data-testid="check-tandc-warning"
                intent="warning"
                title={labels[LabelKey.missingTerms]}
                iconName={"alert-circle-outlined"}
                testid="terms-warning-info-box"
              />
            )}
            {(acceptedConditions & SalesCondition.PrivacyPolicyAccepted) <= 0 && (
              <InformationBox
                data-testid="check-tandc-warning"
                intent="warning"
                title={labels[LabelKey.missingPrivacyPolicy]}
                iconName={"alert-circle-outlined"}
                testid="privacy-warning-info-box"
              />
            )}
            {isLoadingPaymentSession && (
              <div className="py-14">
                <Loading className="loading" size="lg" intent="black" />
              </div>
            )}
            {canShowPaymentElements && isLoadingPaymentSession === false && (
              <div data-testid="payment-container">
                <PaymentSummaryCell leftTextValue={labels[LabelKey.referenceNumber]} rightTextValue={referenceId} />
                <Divider light />
                <PaymentSummaryCell
                  leftTextValue={labels[LabelKey.toPayNow]}
                  rightTextValue={FormattedMoney(paymentValue)}
                />
                <Divider light />
                <PaymentSummaryCell
                  leftTextValue={labels[LabelKey.beneficiary]}
                  rightTextValue={labels[LabelKey.efEducationFirst]}
                />
                <Divider light />
              </div>
            )}
            {canShowPaymentElements && isLoadingPaymentSession === false && (
              <Text variant="label-xl-bold">{labels[LabelKey.paymentMethod]}</Text>
            )}
            <div
              id="paymentForm"
              data-testid="payment-form"
              className={referenceId && !isLoadingPaymentSession ? "block" : "hidden"}
            ></div>
            {canShowPaymentElements && isLoadingPaymentSession === false && referenceId && (
              <Text variant="label-md">{labels[LabelKey.summaryOfBookingPaymentSentToEmail]}</Text>
            )}
            {!isLoadingPaymentSession && showPaymentFailureError && (
              <InformationBox
                testid="payment-error-info-box"
                intent="alert"
                title={labels[LabelKey.paymentStatus_PaymentFailureHeader]}
                content={labels[LabelKey.paymentStatus_PaymentFailureMessage]}
                iconName={"alert-circle-outlined"}
                additionalInfo={getErrorDetailsToDisplay(showPaymentFailureError)}
              />
            )}
          </TBSection>
        )}
        {(isReservationEnabled || showOnlyReservation) &&
          quoteStatus === QuotePaymentStatus.Unreserved &&
          allTermsAccepted(acceptedConditions) && (
            <div className="mx-auto">
              <Button
                aria-label="reservation-button"
                data-testid="reservation-button"
                iconName="locked"
                iconPosition="start"
                intent="secondary-black"
                size="base"
                isLoading={isLoadingReservation}
                onPress={() => sendReservationEvent()}
                isDisabled={(acceptedConditions & SalesCondition.TermsAccepted) <= 0}
              >
                {labels[LabelKey.reservationFor24Hours]}
              </Button>
            </div>
          )}
      </div>
    </ContentLayout>
  );
};

export default Payment;
