import { Guid } from "./Types";

/**
 * Keys for Storyblok Datasource entries for labels
 */

// PLEASE KEEP THEM SORTED ALPHABETICALLY
export enum LabelKey {
  accommodationHasChanged = "accommodationHasChanged",
  accommodationOfYourChoice = "accommodationOfYourChoice",
  addButton = "addButton",
  addInvoiceEmail = "addInvoiceEmail",
  addOptionalItems = "addOptionalItems",
  address = "address",
  addWeeks = "addWeeks",
  agreeToPrivacyPolicy = "agreeToPrivacyPolicy",
  agreeToTermsAndConditions = "agreeToTermsAndConditions",
  back = "back",
  beneficiary = "beneficiary",
  birthCountry = "birthCountry",
  birthCity = "birthCity",
  bookByPartialPayment = "bookByPartialPayment",
  bookingConditions = "bookingConditions",
  bookNow = "bookNow",
  cancel = "cancel",
  change = "change",
  changeAccommodation = "changeAccommodation",
  changeAccommodationTo = "changeAccommodationTo",
  changeAccommodationDescription = "changeAccommodationDescription",
  city = "city",
  completeBooking = "completeBooking",
  confirmAndPay = "confirmAndPay",
  contactUs = "contactUs",
  country = "country",
  currentAccommodation = "currentAccommodation",
  dateOfBirth = "dateOfBirth",
  descriptionSalesUser = "descriptionSalesUser",
  discount = "discount",
  discoverDestination = "discoverDestination",
  downPayment = "downPayment",
  downPaymentDetails = "downPaymentDetails",
  downPaymentLabel = "downPaymentLabel",
  duration2to4Weeks = "duration2to4Weeks",
  duration5orMoreWeeks = "duration5orMoreWeeks",
  durationUpgradeDescription = "durationUpgradeDescription",
  durationWeek = "durationWeek",
  durationWeeks = "durationWeeks",
  efEducationFirst = "efEducationFirst",
  email = "email",
  error = "error",
  firstName = "firstName",
  firstNameLatin = "firstNameLatin",
  fullPayment = "fullPayment",
  fullPaymentDetails = "fullPaymentDetails",
  fullPaymentLabel = "fullPaymentLabel",
  gender = "gender",
  goToReservationButton = "goToReservationButton",
  headerAccommodation = "headerAccommodation",
  headerCourses = "headerCourses",
  headerGeneralFees = "headerGeneralFees",
  headerInsurance = "headerInsurance",
  headerOther = "headerOther",
  headerSalesUser = "headerSalesUser",
  headerTransfer = "headerTransfer",
  hideDetails = "hideDetails",
  howManyWeeksToAdd = "howManyWeeksToAdd",
  included = "included",
  includedInDestinationPrice = "includedInDestinationPrice",
  infoSheet = "infoSheet",
  invoiceEmail = "invoiceEmail",
  lastName = "lastName",
  lastNameLatin = "lastNameLatin",
  learnFaster = "learnFaster",
  medicalDetails_allergies = "medicalDetails_allergies",
  medicalDetails_allergy_dust = "medicalDetails_allergy_dust",
  medicalDetails_allergy_insect = "medicalDetails_allergy_insect",
  medicalDetails_allergy_nuts = "medicalDetails_allergy_nuts",
  medicalDetails_allergy_other = "medicalDetails_allergy_other",
  medicalDetails_allergy_penicilin = "medicalDetails_allergy_penicilin",
  medicalDetails_allergy_pets = "medicalDetails_allergy_pets",
  medicalDetails_allergy_pollen = "medicalDetails_allergy_pollen",
  medicalDetails_dietaryNeeds = "medicalDetails_dietaryNeeds",
  medicalDetails_dietaryNeeds_description = "medicalDetails_dietaryNeeds_description",
  medicalDetails_dietary_glutenFree = "medicalDetails_dietary_glutenFree",
  medicalDetails_dietary_halal = "medicalDetails_dietary_halal",
  medicalDetails_dietary_kosher = "medicalDetails_dietary_kosher",
  medicalDetails_dietary_lactoseFree = "medicalDetails_dietary_lactoseFree",
  medicalDetails_dietary_other = "medicalDetails_dietary_other",
  medicalDetails_dietary_vegan = "medicalDetails_dietary_vegan",
  medicalDetails_dietary_vegetarian = "medicalDetails_dietary_vegetarian",
  medicalDetails_disability_deaf = "medicalDetails_disability_deaf",
  medicalDetails_disability_other = "medicalDetails_disability_other",
  medicalDetails_disability_visuallyImpaired = "medicalDetails_disability_visuallyImpaired",
  medicalDetails_disability_usesWheelchair = "medicalDetails_disability_usesWheelchair",
  medicalDetails_medical_other = "medicalDetails_medical_other",
  medicalDetails_medicalNeeds = "medicalDetails_medicalNeeds",
  medicalDetails_medicalNeeds_asthmatic = "medicalDetails_medicalNeeds_asthmatic",
  medicalDetails_medicalNeeds_description = "medicalDetails_medicalNeeds_description",
  medicalDetails_medicalNeeds_diabetic = "medicalDetails_medicalNeeds_diabetic",
  medicalDetails_medicalNeeds_epileptic = "medicalDetails_medicalNeeds_epileptic",
  medicalDetails_medicalNeeds_medications = "medicalDetails_medicalNeeds_medications",
  medicalDetails_cannot_update_now = "medicalDetails_cannot_update_now",
  middleName = "middleName",
  middleNameLatin = "middleNameLatin",
  missingCountry = "missingCountry",
  missingDetails = "missingDetails",
  missingPrivacyPolicy = "missingPrivacyPolicy",
  missingTerms = "missingTerms",
  myPriceQuotations = "myPriceQuotations",
  nationality = "nationality",
  noOfWeeks = "noOfWeeks",
  numberOfWeeks = "numberOfWeeks",
  optionalItemsAdditionalDescription = "optionalItemsAdditionalDescription",
  original = "original",
  originalDuration = "originalDuration",
  orPayLater = "orPayLater",
  paid = "paid",
  paidAmount = "paidAmount",
  paidReservationAmount = "paidReservationAmount",
  parentDetails = "parentDetails",
  parentRelationship = "parentRelationship",
  payment = "payment",
  paymentInformation = "paymentInformation",
  paymentIsBeingProcessed = "paymentIsBeingProcessed",
  paymentMethod = "paymentMethod",
  paymentReferenceNumber = "paymentReferenceNumber",
  paymentsMade = "paymentsMade",
  paymentStatus_PaymentFailureHeader = "paymentStatus_PaymentFailureHeader",
  paymentStatus_PaymentFailureMessage = "paymentStatus_PaymentFailureMessage",
  paymentStatus_PaymentProcessedHeader = "paymentStatus_PaymentProcessedHeader",
  paymentStatus_PaymentSuccessHeader = "paymentStatus_PaymentSuccessHeader",
  paymentStatus_ReservationFailureHeader = "paymentStatus_ReservationFailureHeader",
  paymentStatus_ReservationFailureMessage = "paymentStatus_ReservationFailureMessage",
  paymentStatus_ReservationSuccessHeader = "paymentStatus_ReservationSuccessHeader",
  paymentStatus_ReservationSuccessMessage = "paymentStatus_ReservationSuccessMessage",
  paymentStatus_ThankYouMessage = "paymentStatus_ThankYouMessage",
  payNow = "payNow",
  payOnline = "payOnline",
  passportNumber = "passportNumber",
  pdf = "pdf",
  pending = "pending",
  phoneCallButton = "phoneCallButton",
  phoneNumber = "phoneNumber",
  pleaseWait = "pleaseWait",
  pleaseWaitRequest = "pleaseWaitRequest",
  priceDetails = "priceDetails",
  privacyPolicy = "privacyPolicy",
  postalCode = "postalCode",
  quoteId = "quoteId",
  quotePaidOn = "quotePaidOn",
  quotePricePerWeek = "quotePricePerWeek",
  quotePriceTotal = "quotePriceTotal",
  quoteSaved = "quoteSaved",
  quoteValidUntil = "quoteValidUntil",
  referenceNumber = "referenceNumber",
  remaining = "remaining",
  removeButton = "removeButton",
  requestIsBeingProcessed = "requestIsBeingProcessed",
  reservationFor24Hours = "reservationFor24Hours",
  reserve = "reserve",
  reserved = "reserved",
  reserveFor = "reserveFor",
  reserveNow = "reserveNow",
  reviewDetailsAndReserve = "reviewDetailsAndReserve",
  save = "save",
  saveAndContinue = "saveAndContinue",
  continue = "continue",
  saveAndPay = "saveAndPay",
  saveAsNew = "saveAsNew",
  saveDetails = "saveDetails",
  saveFailed = "saveFailed",
  saveFailedDescription = "saveFailedDescription",
  seeAll = "seeAll",
  seeLess = "seeLess",
  seeWhatsNext = "seeWhatsNext",
  segmentPricePerWeek = "segmentPricePerWeek",
  segmentTotalPrice = "segmentTotalPrice",
  selectButton = "selectButton",
  selectPaymentMethod = "selectPaymentMethod",
  sendEmailButton = "sendEmailButton",
  sendMessageButton = "sendMessageButton",
  sendMessageButtonMobile = "sendMessageButtonMobile",
  showDetails = "showDetails",
  sortBy = "sortBy",
  sortBy_Created = "sortBy_Created",
  sortBy_Destination = "sortBy_Destination",
  sortBy_Duration = "sortBy_Duration",
  sortBy_TotalPrice = "sortBy_TotalPrice",
  sortBy_WeeklyPrice = "sortBy_WeeklyPrice",
  status = "status",
  studentBasicInformation = "studentBasicInformation",
  studentDetails = "studentDetails",
  studentDetailsDescription = "studentDetailsDescription",
  studentDetailsFullUpgradeDescription = "studentDetailsFullUpgradeDescription",
  studentDetailsFullUpgradeThankYouMessage = "studentDetailsFullUpgradeThankYouMessage",
  studentDetailsFullUpgradeThankYouMessageDescription = "studentDetailsFullUpgradeThankYouMessageDescription",
  studentDetailsFullUpgradeThankYouMessageCompletePayment = "studentDetailsFullUpgradeThankYouMessageCompletePayment",
  studentDetailsFullUpgradeThankYouMessageGoToReservation = "studentDetailsFullUpgradeThankYouMessageGoToReservation",
  studentDetailsSaved = "studentDetailsSaved",
  studentLatinDetails = "studentLatinDetails",
  studentUpdateSteps = "studentUpdateSteps",
  success = "success",
  summary = "summary",
  summaryOfBookingPaymentSentToEmail = "summaryOfBookingPaymentSentToEmail",
  termsAndConditions = "termsAndConditions",
  toBePaid = "toBePaid",
  toPayNow = "toPayNow",
  total = "total",
  totalAfter = "totalAfter",
  updateStudentDetails = "updateStudentDetails",
  upgrade = "upgrade",
  upgradeCost = "upgradeCost",
  upgradeCourse = "upgradeCourse",
  upgradeFailed = "upgradeFailed",
  validationMessage = "validationMessage",
  validationEmailMessage = "validationEmailMessage",
  whatToExpect = "whatToExpect",
  whatsNext = "whatsNext",
  whyExtendYourCourse_Description = "whyExtendYourCourse_Description",
  whyExtendYourCourse_Title = "whyExtendYourCourse_Title",
}

export interface BlokBase {
  _uid: Guid;
  component: string;
}

export interface SbImage {
  filename: string;
  alt: string;
  focus: string;
}

export interface SbUrl {
  linktype: string;
  url: string;
}

export type DatasourceEntry = {
  dimension_value: string;
  id: number;
  name: string;
  value: string;
};
