import "@ilc-technology/luik/tailwindcss";
import "./App.scss";
import { Outlet } from "react-router-dom";
import { AppConfig } from "./AppConfig";
import Script from "./components/Script/Script";
import { apiPlugin, storyblokInit } from "@storyblok/react";
import { SessionContextProvider } from "./contexts/SessionContext/SessionContext";
import { QuoteContextProvider } from "./contexts/QuoteContext/QuoteContext";
import { StoryblokContextProvider } from "./contexts/StoryblokContext/StoryblokContext";
import { NotificationProvider } from "@ilc-technology/luik";
import Fonts from "./components/Fonts/Fonts";
import Analytics from "./components/Analytics/Analytics";
import { storyblokBlocks } from "./components/Storyblok/storyblokBlocks";
import BasePage from "./components/Pages/BasePage/BasePage";
import { getRumClient } from "./Common/services/AwsRumService";

getRumClient();

storyblokInit({
  accessToken: AppConfig.storyBlok.accessToken,
  use: [apiPlugin],
  components: storyblokBlocks,
})

const App: React.FC = () => {
  return (
    <NotificationProvider>
      <SessionContextProvider>
        <StoryblokContextProvider>
          <QuoteContextProvider>
            <div className="App">
              <Analytics />
              <Fonts />
              <Script
                type="text/javascript"
                src={AppConfig.oraclePaymentLibrary.source}
                integrity={AppConfig.oraclePaymentLibrary.integrity}
                crossOrigin="anonymous"
                retries={3}
                errorHandler={() => console.error("Payment Library failed to load after 3 retries.")}
              />
              <Script type="text/javascript" src={AppConfig.cookieConsent.autoblockSource} />
              <Script
                type="text/javascript"
                src={AppConfig.cookieConsent.onetrustSource}
                data={{ "domain-script": AppConfig.cookieConsent.domainScriptId }}
                runCode={(window.OptanonWrapper = () => {})}
              />
              <BasePage withHeader withFooter>
                <Outlet />
              </BasePage>
            </div>
          </QuoteContextProvider>
        </StoryblokContextProvider>
      </SessionContextProvider>
    </NotificationProvider>
  );
};

export default App;
